import React, { useState, useEffect } from 'react';
import { sendVerificationCode, verifyPhone, getAllChannelInfo, ChannelInfoResponse } from '../services/phoneVerification';
import './PhoneVerification.css';
import axios from 'axios';

const PhoneVerification: React.FC = () => {
  const [phone, setPhone] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [message, setMessage] = useState('');
  const [token, setToken] = useState<string | null>(localStorage.getItem('token'));
  const [channelInfo, setChannelInfo] = useState<ChannelInfoResponse[] | null>(null);

  useEffect(() => {
    const checkTokenAndGetChannelInfo = async () => {
      if (token) {
        try {
          const info = await getAllChannelInfo(token);
          setChannelInfo(info);
          localStorage.setItem('channelInfo', JSON.stringify(info));
        } catch (error) {
          console.error('Failed to get channel information:', error);
          setToken(null);
          setChannelInfo(null);
          localStorage.removeItem('token');
          localStorage.removeItem('channelInfo');
        }
      }
    };

    checkTokenAndGetChannelInfo();
  }, [token]);

  const handleSendCode = async () => {
    try {
      await sendVerificationCode({ phone });
      setMessage('验证码发送成功');
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        setMessage(error.response.data.error || '发送验证码失败');
      } else {
        setMessage('发送验证码失败');
      }
    }
  };

  const handleVerifyCode = async () => {
    try {
      const newToken = await verifyPhone({ phone, code: verificationCode });
      setToken(newToken);
      localStorage.setItem('token', newToken);
      setMessage('手机验证成功');
      
      // Fetch channel info and open landing page
      const info = await getAllChannelInfo(newToken);
      setChannelInfo(info);
      localStorage.setItem('channelInfo', JSON.stringify(info));
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        setMessage(error.response.data.error || '验证码验证失败');
      } else {
        setMessage('验证码验证失败');
      }
    }
  };

  const handleLogout = () => {
    setToken(null);
    setChannelInfo(null);
    localStorage.removeItem('token');
    localStorage.removeItem('channelInfo');
  };

  const constructAbsoluteUrl = (url: string) => {
    if (url.startsWith('http://') || url.startsWith('https://')) {
      return url;
    }
    return `https://${url.startsWith('//') ? url.slice(2) : url}`;
  };

  return (
    <div className="phone-verification-container">
      {!token ? (
        <>
          <h1 className="title">手机号验证</h1>
          <p className="subtitle">请输入你绑定的手机号</p>
          <div className="form-container">
            <div className="input-group">
              <label htmlFor="phone">手机号码</label>
              <input
                id="phone"
                type="tel"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="请输入手机号码"
                className="input-field"
              />
            </div>
            <button onClick={handleSendCode} className="button primary">
              发送验证码
            </button>
            <div className="input-group">
              <label htmlFor="code">验证码</label>
              <input
                id="code"
                type="text"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                placeholder="请输入验证码"
                className="input-field"
              />
            </div>
            <button onClick={handleVerifyCode} className="button secondary">
              验证
            </button>
          </div>
        </>
      ) : (
        <>
          <h1 className="title">验证成功</h1>
          {channelInfo && channelInfo.length > 0 && (
            <div className="channel-info">
              <table className="channel-table">
                <thead>
                  <tr>
                    <th>操作</th>
                    <th>详细信息</th>
                  </tr>
                </thead>
                <tbody>
                  {channelInfo.map((channel, index) => (
                    <tr key={channel.id}>
                      <td>
                        <button 
                          className="visit-button"
                          onClick={() => {
                            const absoluteUrl = constructAbsoluteUrl(channel.landing_page_url);
                            window.open(absoluteUrl, '_blank');
                          }}
                        >
                          访问
                        </button>
                      </td>
                      <td className="channel-details">
                        <div><strong>商户:</strong> <b>商户{channel.channel_name} </b></div>
                        <div><strong>落地页链接:</strong> {channel.landing_page_url}</div>
                        <div><strong>创建时间:</strong> {channel.create_time}</div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          <div className="button-container">
           
            <button onClick={handleLogout} className="button secondary">
              登出
            </button>
          </div>
        </>
      )}
      {message && <p className={`message ${message.includes('成功') ? 'success' : 'error'}`}>{message}</p>}
    </div>
  );
};

export default PhoneVerification;